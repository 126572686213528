/* Grundläggande layout */
.c-contact {
  overflow: hidden;
  margin-left: -0.5em;
  margin-right: -0.5em;
  margin-bottom: 2rem;
}

.c-contact__links {
  --c-contact__links-rule-width: 100%;

  width: var(--c-contact__links-rule-width);
  padding: 1em 1em 0 1em;
  margin: 0 auto 0;
}

@media screen and (min-width: 48rem) {
  .c-contact__links {
    --c-contact__links-rule-width: 70%;
  }
}

.c-contact__link {
  display: inline-block;
  padding: 0.5em 1em;
  border-radius: 100px;
  background-color: rgba(0, 0, 0, 0);
  transition: 0.333s;
  text-decoration: none;
  color: #222;
}

.c-contact__link:hover {
  background-color: var(--orange);
  color: #fff;
}

.c-contact__link:active {
  color: black;
}

/* Grid på section-nivå */
.c-contact__section {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(1, minmax(100px, 1fr));
  grid-template-rows: repeat(2, auto);
  align-items: center;
  margin-top: 1rem;
}

@media only screen and (min-width: 48rem) {
  .c-contact__section {
    grid-gap: 10px;
    grid-template-columns: 0.1fr minmax(450px, 1fr) 1fr 0.1fr;
    grid-template-rows: repeat(2, auto);
    align-items: flex-start;
  }

  .c-contact__heading {
    grid-column-start: 2;
    grid-column-end: 3;
    padding-left: 10rem;
  }

  .c-contact__text {
    grid-column-start: 3;
    grid-column-end: 4;
    padding: 2rem 0;
  }
}

@media only screen and (min-width: 70rem) {
  .c-contact__section {
    grid-template-columns: 0.4fr minmax(450px, 1fr) 1fr 0.4fr;
  }
}

/* Formelement */
form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

input, textarea, button {
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  font-size: 1rem;
}

button {
  background-color: var(--orange);
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #e67300;
}

/* Stil för kontaktinformationen */
.c-contact__text {
  padding: 1rem 2rem;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 0.5rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin: 1rem 0;
}

.c-contact__text address {
  font-style: normal; /* Ingen kursiv stil */
  line-height: 1.6;
  color: #333;
}

.c-contact__text p {
  margin: 0.5rem 0;
}

.c-contact__text a {
  color: var(--orange, #ff7f50); /* Anpassa färgen till temat */
  text-decoration: none;
  transition: color 0.3s ease;
}

.c-contact__text a:hover {
  text-decoration: underline;
  color: #e67300; /* Mörkare orange för hover */
}

/* Grundläggande layout för listan */
.c-social__list {
  list-style: none; /* Tar bort bullets */
  margin: 0;
  padding: 0;
  display: flex; /* Flexbox för horisontell eller vertikal layout */
  flex-direction: column; /* Stapla vertikalt */
  gap: 1rem; /* Mellanrum mellan listobjekt */
}

/* Länkar */
.c-social__link {
  display: flex;
  align-items: center;
  gap: 0.5rem; /* Mellanrum mellan ikon och text */
  text-decoration: none;
  font-size: 1.2rem; /* Justera textstorlek */
  color: #333; /* Neutral textfärg */
  transition: color 0.3s ease;
}

.c-social__link:hover {
  color: var(--orange, #ff7f50); /* Orange vid hover */
}

/* Ikoner */
.c-social__icon {
  width: 1.5rem; /* Storlek på ikoner */
  height: auto;
  fill: var(--orange, #ff7f50); /* Matcha färgtemat */
  transition: fill 0.3s ease;
}

.c-social__link:hover .c-social__icon {
  fill: #e67300; /* Mörkare orange vid hover */
}
