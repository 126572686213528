/**
 * Styles for search
 */

input[type=text]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=text]::-ms-reveal {  display: none; width : 0; height: 0; }
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

.c-search {
  transition: transform 1s ease-in-out;
  transform: translateX(120%);
  position: fixed;
  width: 100%;
  height: fit-content;
  top: 3.5rem;
  right: 0;
  bottom: 0;
  display: block;
  background: #fff;
}

.c-search.is-open {
  bottom: 0;
  display: block;
  border-top: 1px solid #eee;
  transition: transform 1s ease-in-out;
  transform: translateX(0);
}

.c-btn--search {
  margin-right: 0.9375rem;
  width: 2rem;
  height: 2rem;
}

.c-btn--search.hide {
  display: none;
}

.c-btn__div--search {
  color: var(--darkgrey);
  position: relative;
  width: 1.1085rem;
  height: 1.1085rem;
  border: solid 0.15rem currentColor;
  border-radius: 100%;
  transform: translateY(-0.1rem) translateX(-0.55rem) rotate(-45deg);
  transition: opacity 0.3s linear;
  background: transparent;
  opacity: 1;
}
  
.c-btn__div--search::before {
  content: '';
  position: absolute;
  top: 1.1085rem;
  left: 0.3125rem;
  height: 0.5rem;
  width: 0.15rem;
  background-color: currentColor;
}

.c-btn__div--search.is-open {
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s linear;
}
  
.c-btn--search > .c-btn__div {
  position: absolute;
  opacity: 0;
}

.c-btn--search > .c-btn__div--search {
  opacity: 1;
}

.c-btn--search.is-open > .c-btn__div--search {
    opacity: 0;
}

.c-btn--search.is-open > .c-btn__div:first-child {
  transform: translateY(0.65rem) rotate(45deg);
  position: static;
  opacity: 1;
  transition: opacity 0.3s linear;
}

.c-btn--search.is-open > .c-btn__div:nth-child(2) {
  transform: scale(0);
  position: static;
  opacity: 1;
  transition: opacity 0.3s linear;
}

.c-btn--search.is-open > .c-btn__div:nth-child(3) {
  transform: translateY(-0.65rem) rotate(-45deg);
  position: static;
  opacity: 1;
  transition: opacity 0.3s linear;
}

.c-btn--search-form {
  border: 0;
  border-top-right-radius: 27px;
  border-bottom-right-radius: 27px;
  padding: 25px;
  background: white;
}

.c-btn--search-form > .c-btn__div {
    opacity: 0;
    position: absolute;
}

.c-btn--search-form > .c-btn__div--search {
    opacity: 1;
    position: absolute;
}

.c-btn--search-form.hide > .c-btn__div {
    opacity: 1;
}

.c-btn--search-form.hide > .c-btn__div--search {
    opacity: 0;
}

.c-btn--search-form.hide  > .c-btn__div:first-child {
    transform: translateY(0.15rem) rotate(45deg);
    position: static;
    opacity: 1;
    transition: opacity 0.3s linear;
}
  
.c-btn--search-form.hide > .c-btn__div:nth-child(2) {
    transform: scale(0);
    position: static;
    opacity: 1;
    transition: opacity 0.3s linear;
}
  
.c-btn--search-form.hide > .c-btn__div:nth-child(3) {
    transform: translateY(-0.15rem) rotate(-45deg);
    position: static;
    opacity: 1;
    transition: opacity 0.3s linear;
}

._Search__search-form {
  padding: 0 1.25rem;
  width: 100%;
  height: 3.5rem;
  background: var(--grey);
  padding: 2px;
  border-radius: 28px;
  display: flex;
}

._Search__search-form.is-active {
    background: var(--orange);
}

.Search {
  padding: 1rem;
}

.Search [type="search"] {
  border: none;
  width: 100%;
  height: 100%;
  font-size: 1.25rem;
  letter-spacing: -1px;
  outline: none;
  border: 0;
  min-width: 0;
  display: flex;
  flex: 1;
  border-top-left-radius: 27px;
  border-bottom-left-radius: 27px;
  border-top-right-radius: 0;
  padding: 1rem;
}

.Search__results-container {
  background: #fafafa;
  padding: 1rem 1.25rem 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  top: 3.5rem;
  left: 0;
  right: 0;
  bottom: 0;
}

.Search__results-container a {
  color: #222;
}

.Search__loading-indicator {
  text-align: center;
  font-size: 1.625rem;
  color: #666;
  margin-top: 1rem;
}

.header_component.open .search_component {
  position: fixed;
  top: 3.5rem;
  bottom: 0;
  display: block;
  background: #fff;
  left: 0;
  right: 0;
  border-top: 1px solid #eee;
}
