:root {
  /* colors */
  --grey: #e5e5e5;
  --darkgrey: #666;
  --black: #222;
  --orange: #f7941e;

  /* font sizes */
  --large-font-size: 2.2rem;
}

@media screen and (min-width: 48rem) {
  :root {
    --large-font-size: 3rem;
  }
}

@media screen and (min-width: 70rem) {
  :root {
    --large-font-size: 3.8rem;
  }
}
