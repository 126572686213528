/*
  Common styles.
*/

/* heebo-regular - latin */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;  
  src: local('Heebo'), local('Heebo-Regular'),
       url('../fonts/heebo-v5-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/heebo-v5-latin-regular.woff') format('woff'); /* Modern Browsers */
}

/* heebo-300 - latin */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 300;
  font-display: swap;  
  src: local('Heebo Light'), local('Heebo-Light'),
       url('../fonts/heebo-v5-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/heebo-v5-latin-300.woff') format('woff'); /* Modern Browsers */
}

/* heebo-700 - latin */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 700;
  font-display: swap;  
  src: local('Heebo Bold'), local('Heebo-Bold'),
       url('../fonts/heebo-v5-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/heebo-v5-latin-700.woff') format('woff'); /* Modern Browsers */
}

*,:after,:before {
  box-sizing: border-box;
}

body {
  background-color: #fff;
  color: #222;
  font: normal 400 100% 'Heebo', sans-serif;
  margin: 0;
}

#page {
  width: 100%;
  position: relative;
  min-height: 100vh;
}

.offscreen {
  height: 1px;
  left: -10000px;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px;
}

h2 {
  line-height: 1.2;
}

img {
  max-width: 100%;
}

figure {
  margin: 0 auto 0.5em;
}

[role="banner"] {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 20;
}

.c-footer {
  border-top: 1px solid #eee;
  margin-top: auto;
  display: flex;
  height: 2.5rem;
  align-items: center;
  justify-content: space-around;
  font-size: 1.3125rem;
  color: #1a1a1a;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 20;
}

.c-footer__link {
  text-decoration: none;
  color: var(--black);
}

.c-footer__link:hover {
  color: var(--orange);
}

.logo {
  display: inline-block;
  flex: 1 1 auto;
}

.logo img {
  height: 2.8rem;
  margin-top: 0.3rem;
  display: block;
}

@keyframes spinner {
  to { transform: rotate(360deg); }
}

.svg-icon {
  display: inline-block;
  vertical-align: middle;
  height: 1.85em;
  width: 1.85em;
  fill: #666;
}

.svg-icon.loading {
  animation: spinner 1.5s linear infinite;
}

.header {
  display: flex;
  height: 3.5rem;
  align-items: center;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  background: rgba(255, 255, 255, 0.95);
}

/*header_component and navigation seems to be the actual hambuger*/

.header_component {
  padding-right: 0.9375rem;
}

.c-pagecontent-main.hide {
  filter: blur(5px) grayscale(50%);
}

.c-pagecontent-main {
  --responsive-padding: 1rem;
  transition-property: filter;
  transition-duration: 1s;
}

@media (min-width: 100rem) {
  .c-pagecontent-main {
    --responsive-padding: auto;
  }
}

.c-pagecontent-main {
  margin-right: var(--responsive-padding);
  margin-left: var(--responsive-padding);
  max-width: 100rem;
  padding-top: 4.5rem;
  padding-bottom: 2.5rem;
}

.c-headline {
  margin-left: -0.5em;
  margin-right: -0.5em;
}

.c-headline__title {
  font-size: var(--large-font-size);
  margin-top: 1em;
  margin-bottom: 0.5em;
  text-align: center;
  letter-spacing: -0.06667em;
  padding-bottom: 0;
}

.c-headline__intro {
  text-align: center;
  max-width: 55em;
  margin: 0 auto 0;
  padding: 0.8rem 0.8rem 0 0.8rem;
}

.c-headline__inlinelink {
  font-size: 1em;
  color: #000;
  display: inline;
  font-weight: 700;
}

.c-headline__inlinelink a span {
  font-size: 1em;
  color: #000;
  margin-left: 0.4em;
  display: inline;
  font-weight: bold;
  text-decoration: none; 
}


.c-headline__inlinelink  > a {
  text-decoration: none;
  font-weight: 700;
  color: #000;
}

.c-headline__inlinelink a:hover {
  text-decoration: underline;
}

.c-headline__inlinelink a:visited {
  color: #000; 
}

.c-headline__date {
  text-align: center;
  color: #767676;
  display: block;
  font-size: small;
}

.c-headline__hero--small {
  display: block;
  margin: 0 auto;
  width: 12rem;
  padding-top: 1rem;
}

.c-horizontal-rule {
  --c-horizontal-rule-width: 100%;

  width: var(--c-horizontal-rule-width);
  border: 0;
  height: 0.8px;
  background-color: var(--grey);
  margin: 2rem 1rem 2rem 1rem;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 48rem) {
  .c-horizontal-rule {
    --c-horizontal-rule-width: 70%;
  }
}

.page-sub-headline {
  font-size: 1em;
  margin-top: 1em;
  margin-bottom: 0.5em;
  text-align: center;
  letter-spacing: -0.06667em;
}

.content-block {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  margin-left: -0.5em;
  margin-right: -0.5em;
}

.content-item {
  flex: 1 1 25%;
  vertical-align: top;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

/* large screens */
@media all and (min-width: 500px) {
  .content-block {
    flex-flow: row wrap;
  }

  /* hide horizontal-rule separators when displaying in rows */
  .content-block > .c-horizontal-rule {
    display: none;
  }
}
