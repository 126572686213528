/*
  Styles for category pages
*/

#category-data-table table {
  flex-grow: 2;
  width: 100%;
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

#category-data-table thead {
  font: normal 0.9rem 'nimbus_sans_lbold', sans-serif;
  color: #000;
  text-align: center;
  letter-spacing: -0.06667em;
  background: #f7941e;
}

#category-data-table thead tr th:nth-child(1) {
  text-align: left;
}

#category-data-table tbody {
  font: normal 1rem 'nimbus_sans_lregular', sans-serif;
  text-align: center;
}

#category-data-table tbody tr td:nth-child(1) {
  text-align: left;
}

#category-data-table table td {
  border-left: 1px solid #ccc;
}

h1 {
  font-variant: bold;
  font-size: 1.5rem;
  margin: 0;
  letter-spacing: -0.06667em;
  line-height: 1.2;
}

h2 {
  font-variant: normal;
  font-size: 1.375rem;
  margin: 0;
  padding-bottom: 1rem;
  font-weight: 400;
}

h3 {
  font-variant: normal;
  font-size: 1rem;
  margin: 0;
}

#category-container {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(1, minmax(100px, 1fr));
  grid-template-rows: repeat(2, auto);
  align-items: center;
  margin-left: -0.5em;
  margin-right: -0.5em;
}

#category-picture {
  flex-grow: 1;
  padding: 1rem;
  padding-top: 2.2rem;
  justify-self: end;
}

#category-titel {
  padding: 1rem;
}

#category-description-long {
  display: flex;
  align-self: start;
}

#category-description-long-text {
  flex-grow: 1;
  min-width: 320px;
}

#category-description-long-padding {
  flex-grow: 1;
}

.category-data-horizontal-separator {
  border: 0;
  height: 1px;
  background-color: #ccc;
  display: none;
}

#category-data {
  flex-grow: 2;
  overflow-x: auto;
}

#category-data-titel {
  display: none;
}

#category-data-table {
  display: flex;
  padding-bottom: 2rem;
}

#category-data-table-padding {
  display: none;
}

.carousel > input[type="radio"]:nth-child(1):checked ~ .carousel__prev > label:nth-child(5),
 .carousel > input[type="radio"]:nth-child(1):checked ~ .carousel__next > label:nth-child(2),
 .carousel > input[type="radio"]:nth-child(2):checked ~ .carousel__prev > label:nth-child(1),
 .carousel > input[type="radio"]:nth-child(2):checked ~ .carousel__next > label:nth-child(3),
 .carousel > input[type="radio"]:nth-child(3):checked ~ .carousel__prev > label:nth-child(2),
 .carousel > input[type="radio"]:nth-child(3):checked ~ .carousel__next > label:nth-child(4),
 .carousel > input[type="radio"]:nth-child(4):checked ~ .carousel__prev > label:nth-child(3),
 .carousel > input[type="radio"]:nth-child(4):checked ~ .carousel__next > label:nth-child(5),
 .carousel > input[type="radio"]:nth-child(5):checked ~ .carousel__prev > label:nth-child(4),
 .carousel > input[type="radio"]:nth-child(5):checked ~ .carousel__next > label:nth-child(1) {
  opacity: 1 !important;
  z-index: 3;
}

.carousel {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel > input[type="radio"] {
  position: absolute;
  left: 0;
  opacity: 0;
  top: 0;
}

.carousel > input[type="radio"]:checked ~ .carousel__items .carousel__item,
 .carousel > input[type="radio"]:checked ~ .carousel__prev > label,
 .carousel > input[type="radio"]:checked ~ .carousel__next > label {
  opacity: 0;
  display: none;
}

.carousel > input[type="radio"]:nth-child(1):checked ~ .carousel__items .carousel__item:nth-child(1) {
  opacity: 1;
  display: block;
}

.carousel > input[type="radio"]:nth-child(1):checked ~ .carousel__nav > label:nth-child(1) {
  background: #ccc;
  cursor: default;
  pointer-events: none;
}

.carousel > input[type="radio"]:nth-child(2):checked ~ .carousel__items .carousel__item:nth-child(2) {
  opacity: 1;
  display: block;
}

.carousel > input[type="radio"]:nth-child(2):checked ~ .carousel__nav > label:nth-child(2) {
  background: #ccc;
  cursor: default;
  pointer-events: none;
}

.carousel > input[type="radio"]:nth-child(3):checked ~ .carousel__items .carousel__item:nth-child(3) {
  opacity: 1;
  display: block;
}

.carousel > input[type="radio"]:nth-child(3):checked ~ .carousel__nav > label:nth-child(3) {
  background: #ccc;
  cursor: default;
  pointer-events: none;
}

.carousel > input[type="radio"]:nth-child(4):checked ~ .carousel__items .carousel__item:nth-child(4) {
  opacity: 1;
  display: block;
}

.carousel > input[type="radio"]:nth-child(4):checked ~ .carousel__nav > label:nth-child(4) {
  background: #ccc;
  cursor: default;
  pointer-events: none;
}

.carousel > input[type="radio"]:nth-child(5):checked ~ .carousel__items .carousel__item:nth-child(5) {
  opacity: 1;
  display: block;
}

.carousel > input[type="radio"]:nth-child(5):checked ~ .carousel__nav > label:nth-child(5) {
  background: #ccc;
  cursor: default;
  pointer-events: none;
}

.carousel__items {
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: relative;
}

.carousel__item {
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 612ms ease-in-out;
}

.carousel__item img {
  width: 100%;
  vertical-align: middle;
}

/*
  hide these for now. Maybe we can add these later.
*/
.carousel__prev,
 .carousel__next {
  display: none;
}

.carousel__prev > label,
 .carousel__next > label {
  border: 1px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease;
  opacity: 0;
  z-index: 2;
}

.carousel__prev > label:hover,
 .carousel__prev > label:focus,
 .carousel__next > label:hover,
 .carousel__next > label:focus { opacity: 0.5 !important; }

.carousel__prev > label::before,
 .carousel__prev > label::after,
 .carousel__next > label::before,
 .carousel__next > label::after {
  content: "";
  position: absolute;
  width: inherit;
  height: inherit;
}

.carousel__prev > label::before,
 .carousel__next > label::before {
  background: -webkit-gradient(linear, left bottom, left top, from(#fff), color-stop(10%, #fff), color-stop(10%, rgba(51, 51, 51, 0))), -webkit-gradient(linear, right top, left top, from(#fff), color-stop(10%, #fff), color-stop(10%, rgba(51, 51, 51, 0)));
  background: -webkit-linear-gradient(bottom, #fff 0%, #fff 10%, rgba(51, 51, 51, 0) 10%), -webkit-linear-gradient(right, #fff 0%, #fff 10%, rgba(51, 51, 51, 0) 10%);
  background: -o-linear-gradient(bottom, #fff 0%, #fff 10%, rgba(51, 51, 51, 0) 10), -o-linear-gradient(right, #fff 0%, #fff 10%, rgba(51, 51, 51, 0) 10%);
  background: linear-gradient(to top, #fff 0%, #fff 10%, rgba(51, 51, 51, 0) 10%), linear-gradient(to left, #fff 0%, #fff 10%, rgba(51, 51, 51, 0) 10%);
  width: 60%;
  height: 60%;
  top: 20%;
}

.carousel__prev > label {
  left: 2%;
}

.carousel__prev > label::before {
  left: 35%;
  top: 20%;
  transform: rotate(135deg);
}

.carousel__next > label {
  right: 2%;
}

.carousel__next > label::before {
  left: 10%;
  transform: rotate(315deg);
}

.carousel__nav {
  text-align: center;
  width: 100%;
  z-index: 3;
  padding-top: 2.4rem;
}

.carousel__nav > label {
  border: 1px solid #ccc !important;
  border-radius: 20px;
  display: inline-block;
  margin: 0.5em 0.2em 0;
  height: 1em;
  width: 1em;
  cursor: pointer;
}


.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media only screen and (min-width: 700px) {
  #category-container {
    grid-gap: 20px;
    grid-template-columns: 0.4fr minmax(450px, 1fr) 1fr 0.4fr;
    grid-template-rows: repeat(2, auto);
    font: normal 0.8125rem 'nimbus_sans_lregular', sans-serif;
    align-items: flex-start;
  }

  #category-picture {
    grid-column-start: 2;
    grid-column-end: 3;
  }

  #category-titel {
    grid-column-start: 3;
    grid-column-end: 4;
  }

  #category-data {
    grid-column-start: 2;
    grid-column-end: 4;
  }

  #category-data-table-padding {
    flex-grow: 1;
    display: block;
  }

  .category-data-horizontal-separator {
    display: block;
  }

  #category-data-titel {
    display: block;
  }

  #category-data-titel h1 {
    font: bold 1.25rem 'nimbus_sans_lbold', sans-serif;
    padding: 1.5rem;
    padding-left: 3rem;
  }

  #category-data-table {
    padding: 2rem;
    padding-top: 0.5rem;
  }

  #category-data-table thead {
    font: normal 0.9rem 'nimbus_sans_lbold', sans-serif;
    color: #000;
    text-align: center;
    letter-spacing: -0.06667em;
    background: #f7941e;
  }

  #category-data-table tbody {
    font: normal 0.8125rem 'nimbus_sans_lregular', sans-serif;
    text-align: center;
  }
}

