.image {
  display: block;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
}

.img-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
}

.img-item {
  position: relative;
}

.img-item img {
  width: 100%;
  height: auto;
  display: block;
}

figcaption {
  margin-top: 8px;
  font-size: 0.9em;
  color: #555;
  display: inline;
}

figcaption > a {
  text-decoration: none;
  font-weight: 700;
  color: #000;
}

figcaption a:hover {
  text-decoration: underline;
}

figcaption a:visited {
  color: #000; 
}

figcaption a h2 {
  font-size: 1em;
  color: #000;
  margin-left: 0.4em;
  display: inline;
  font-weight: bold;
  text-decoration: none; 
}

@media (min-width: 48rem) {
  .img-grid {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}

@media (min-width: 70rem) {
  .img-grid {
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  }
}

@media (min-width: 100rem) {
  .img-grid {
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  }
}