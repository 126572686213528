/**
 * Styles for about page
 */

/**
 * Clearfix our filter context.
 */
.c-about {
  overflow: hidden;
  margin-left: -0.5em;
  margin-right: -0.5em;
  margin-bottom: 2rem;
}

.c-about__links {
  --c-about__links-rule-width: 100%;

  width: var(--c-about__links-rule-width);
  padding: 1em 1em 0 1em;
  margin: 0 auto 0;
}

@media screen and (min-width: 48rem) {
  .c-about__links {
    --c-about__links-rule-width: 70%;
  }
}

.c-about__link {
  display: inline-block;
  padding: 0.5em 1em;
  border-radius: 100px;
  background-color: rgba(0, 0, 0, 0);
  transition: 0.333s;
  text-decoration: none;
  color: #222;
}

.c-about__link:hover {
  background-color: var(--orange);
  color: #fff;
}

.c-about__link:active {
  color: black;
}
