/**
 * Styles for navigation
 */

.c-navigation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  transition: transform 1s ease-in-out;
  transform: translateX(100%);
  width: 100%;
  font-size: 1.4375rem;
  z-index: 10;
  height: 100%;
  position: fixed;
}

.c-navigation.is-open {
  transform: translateX(0);
}

@media all and (min-width: 750px) {
  /**
  * Two items per row on larger screens.
  */
  .c-navigation {
    width: 50%;
  }
}

.c-navigation__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  list-style-image: none;
  height: 100%;
  top: 5rem;
  width: 100%;
  position: relative;
}

.c-navigation__item {
  display: block;
}

.c-navigation__link {
  text-decoration: none;
  color: #222;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1rem 1.25rem;
}

.c-navigation__title {
  flex: 1 0 auto;
}

.c-navigation__animation {
  display: inline-block;
  vertical-align: middle;
  height: 3.5rem;
  width: 3.5rem;
  align-self: baseline;
}

.c-navigation__arrow {
  box-sizing: border-box;
  height: 1.25rem;
  width: 1.25rem;
  border-style: solid;
  border-color: var(--darkgrey);
  border-width: 0 0.1rem 0.1rem 0;
  transform: rotate(225deg);
  transition: border-width, transform 0.3s;
  transition-timing-function: cubic-bezier(0.4, 0, 0, 1);
  overflow: hidden;
  top: 1rem;
  right: -1.9rem;
  position: relative;
}

.c-navigation__arrow.is-open {
  transform: translateY(-0.875rem) rotate(45deg);
}

.c-navigation__sub-menu {
  background: #fafafa;
  border-top: 1px solid #eee;
  text-indent: 1rem;
}

.c-navigation__sub-menu__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  list-style-image: none;
}

.c-navigation__sub-menu__item:hover {
  background: #fff;
}

.c-navigation__sub-menu__link {
  color: #444;
  font-size: 0.9375rem;
  padding: 1em 1.25rem;
  text-decoration: none;
  display: flex;
}
