/**
 * Styles for reseller pages
 */

/**
 * Clearfix our filter context.
 */
.c-resellers {
  overflow: hidden;
  margin-left: -0.5em;
  margin-right: -0.5em;
}

/**
 * Make the targets hover at the top of the viewport so that we never get
 * awkward page jumps when filtering.
 */
.c-resellers__target {
  position: fixed;
}

.c-resellers__links {
  padding: 1em 1em 0 1em;
  margin-bottom: 1em;
  background-color: #fff;
}

.c-filter__link {
  display: inline-block;
  padding: 0.5em 1em;
  border-radius: 100px;
  background-color: rgba(0, 0, 0, 0);
  transition: 0.333s;

  /* add mixin */
  text-decoration: none;
  color: #222;
}

.c-filter__link.is-active {
  background-color: var(--orange);
  color: #fff;
  pointer-events: none;
}

.c-filter__link--reset {
  border-right: 1px solid #e5e5e5;
  border-radius: 0;
}

.c-filter__item {
  float: left;
  width: calc(80% - 1em);
  margin: 0 0.5em 1em 1em;
  transition: 0.333s;
  opacity: 1;
  visibility: visible;
}

.c-filter__item.is-hidden {
  opacity: 0;
  visibility: hidden;
}

@media all and (min-width: 900px) {
  /**
   * Two items per row on larger screens.
   */
  .c-filter__item {
    width: calc(33.333333333% - 1em);
  }
}

.c-resellers__reseller {
  padding: 0 0 1rem 0.6rem;
  border: 1px solid #eee;
  margin-bottom: 1rem;
  transition: margin-left 0.3s linear, color 0.3s linear;
  background-color: #fff;

  /* add mixin */
  text-decoration: none;
  color: #222;
}

.c-resellers__reseller:hover,
.c-resellers__reseller:active {
  margin-left: 1.5rem;
  font-weight: 600;
  border: 1px solid var(--orange);
}

.c-reseller {
  overflow: hidden;
  margin-left: -0.5em;
  margin-right: -0.5em;
}

.c-reseller__common-data {
  text-align: center;
  background-color: #fff;
}

.c-reseller__data {
  float: left;
  width: calc(80% - 1em);
  margin: 0 0.5em 1em 1em;
  padding: 0 0 1rem 0.6rem;
  border: 1px solid #eee;
  background-color: #fff;
}

.c-reseller__data.is-clickable {
  transition: margin-left 0.3s linear, color 0.3s linear;
}

.c-reseller__data.is-clickable:hover,
.c-reseller__data.is-clickable:active {
  margin-left: 1.5rem;
  border: 1px solid var(--orange);
}

@media all and (min-width: 900px) {
  /**
   * Two items per row on larger screens.
   */
  .c-reseller__data {
    width: calc(33.33333% - 1em);
  }
}

.c-reseller__link {
  text-decoration: none;
  color: var(--black);
}

.c-reseller__link:hover,
.c-reseller__link:active {
  font-weight: 600;
}
