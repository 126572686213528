/*
  Styles for article pages
*/

.c-article {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(1, minmax(100px, 1fr));
  grid-template-rows: repeat(2, auto);
  align-items: center;
  margin-top: 1rem;
}

.c-article__picture {
  flex-grow: 1;
  padding: 2rem 0 0 0;
  justify-self: end;
}

.c-article__picture > div.picture > figure > figcaption {
  display: none;
}

@media only screen and (min-width: 48rem) {
  .c-article {
    grid-gap: 10px;
    grid-template-columns: 0.1fr minmax(450px, 1fr) 1fr 0.1fr;
    grid-template-rows: repeat(2, auto);
    align-items: flex-start;
  }

  .c-article__picture {
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .c-article__heading {
    grid-column-start: 2;
    grid-column-end: 3;
    padding-left: 10rem;
  }

  .c-article__text {
    grid-column-start: 3;
    grid-column-end: 4;
    padding: 2rem 0 2rem 0;
  }
}

@media only screen and (min-width: 70rem) {
  .c-article {
    grid-template-columns: 0.4fr minmax(450px, 1fr) 1fr  0.4fr;
  }
}
