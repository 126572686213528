/**
 * Styles for hamburger menu
 */

.c-btn {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  align-items: flex-end;
}

.c-btn--menu {
  width: 2rem;
  height: 2rem;
  z-index: 20;
}

.c-btn:focus {
  outline: none;
}

.c-btn__div {
  width: 2rem;
  height: 0.15rem;
  background: var(--darkgrey);
  border-radius: 10px;
  position: relative;
}

.c-btn--menu > .c-btn__div {
  transition: all 0.3s linear;
}

.c-btn--menu > .c-btn__div:nth-child(2) {
  width: 1.5rem;
}

.c-btn--menu.is-open > .c-btn__div:first-child {
  transform: translateY(0.65rem) rotate(45deg);
}

.c-btn--menu.is-open > .c-btn__div:nth-child(2) {
  transform: scale(0);
}

.c-btn--menu.is-open > .c-btn__div:nth-child(3) {
  transform: translateY(-0.65rem) rotate(-45deg);
}
