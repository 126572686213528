.used-details__list {
  display: block;
  margin: 0;
  padding: 0.5rem;
}

.used-details__list-container {
  display: flex;
  flex-wrap: wrap;
}

.used-details__list-name {
  font-weight: 700;
}

.used-details__list-value {
  margin-left: 0.5rem;
}
